import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import InputTemplate from './InputTemplate';
import { styled } from 'styled-components';
import { FormControl, TextField } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { EDateFormat } from 'shared/enum/date-format.enum';
const TimePickerBody = styled(TimePicker)`
    width: 100%;
    min-width: 0 !important;
    background-color: ${(props) => props.disabled ? "whitesmoke" : "none"};
    // padding-bottom: 1em !important;
    // overflow: initial !important;
    padding-top: -8px !important;
    .MuiOutlinedInput-input {
    padding: 16px 14px
    }
    .Mui-disabled {
    background-color: #f5f5f5 !important;
    border-color: #9b9b9b;
    }
    .css-1pduc5x-MuiStack-root>.MuiTextField-root {
        width: 100% !important;
    }
    
`;

interface IProps {
    label?: string;
    className?: string;
    ampm?: boolean;
    value?: any;
    info?: any;
    error?: string;
    required?: boolean;
    onChange?(value: any, context: any): void;
    defaultValue?: any;
}

const TimePickerField: React.FC<IProps> = ({
    label,
    className,
    ampm,
    value,
    info,
    error,
    required,
    onChange,
    defaultValue
}) => {
    return (
        <InputTemplate className={className} label={label} info={info} error={error} required={required}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['TimePicker']}>
                    <TimePickerBody
                        label={label}
                        ampm={ampm}
                        value={value}
                        onChange={onChange}
                        viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                        }}
                    />
                </DemoContainer>
            </LocalizationProvider>
        </InputTemplate>
    );
}

export default TimePickerField;