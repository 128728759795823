import styled from "@emotion/styled";
import CircularProgress from "@mui/material/CircularProgress";
import React, { FC, Suspense, useEffect, useState } from "react";

const CircularProgressStyles = styled(CircularProgress)`
  position: absolute;
  left: 50%;
  top: 40%;
`;

const StyledPageLoading = styled.div<{ isLoading: boolean }>`
  display: ${(props) => (props.isLoading ? "block" : "none")};
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 200;
`;

interface IPageLoadingProps {
  loading?: boolean;
}

const PageLoading: FC<IPageLoadingProps> = ({ loading = false }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (loading) setIsLoading(true);
    else setIsLoading(false);
  }, [loading]);

  // const Loading = () => {
  //   return (
  //     <figure>
  //       <div className="dot white"></div>
  //       <div className="dot"></div>
  //       <div className="dot"></div>
  //       <div className="dot"></div>
  //       <div className="dot"></div>
  //     </figure>
  //   );
  // };

  return (
    <StyledPageLoading isLoading={isLoading}>
      <figure>
        <div className="dot white"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </figure>
    </StyledPageLoading>
  );
};

export default PageLoading;