import styled from "@emotion/styled";
import { ConfirmMessage } from "components/ui/Alert";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dispatch } from "redux";
import constants from "shared/constants/constants";
import { EAlertStatus } from "shared/enum/alert";
import { IReducer } from "store";
import { getUserInfo, logout } from "store/authenticate/authenticate.action";
// import LogoutIcon from '@mui/icons-material/Logout';

const HEADER_HEIGHT = constants.HEADER_HEIGHT;

const AppNameWrapper = styled.div`
  height: ${HEADER_HEIGHT}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 8px;
  @media only screen and (max-width: 420px) {
    display: flex;
    height: 40px;
  }
`;

const UserProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    /* For tablet: */
    margin-left: auto;
  }
`;

const UserProfileInfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-right: 30px;
  @media only screen and (max-width: 767px) {
    /* For tablet: */
    display: none;
  }
`;

const HeaderText = styled.div`
  color: white;
  margin: 0;
  font-size: 18px;
  user-select: none;
  height: 1em;
  cursor: pointer;
`;

const UserNameText = styled(HeaderText)`
  font-size: 20px;
  font-weight: bold;
`;

const UserText = styled(HeaderText)`
  font-size: 10px;
  flex: 3;
  margin-top: 8px;
  width: 70px;
`;

const UserLabel = styled(UserText)`
  flex: 1;
  font-weight: bold;
  // margin: 3px;
`;

const UserImage = styled.img`
  height: ${HEADER_HEIGHT}px;
  width: ${HEADER_HEIGHT}px;
  margin-right: 10px;
  cursor: pointer;
`;

const VerticalDivider = styled.div`
  border-left: 1px solid #6d6d77;
  height: 50px;
`;

const LogoutButton = styled.div`
  height: ${HEADER_HEIGHT}px;
  width: ${HEADER_HEIGHT}px;
  margin-right: 16px;
  margin-left: 16px;
  cursor: pointer;
`;

const HeaderHighlightText = styled(HeaderText)`
  color: #f7b801;
`;

const TextRow = styled.div`
  display: flex;
`;

// const LogoutIconWrapper = styled(LogoutIcon)`
//   font-size: 2.5rem;
//   margin: 12px;
//   color: #ffffff;
// `;


export const AppName: FC = () => {
  const { t } = useTranslation("main");
  const navigate = useNavigate();

  const goHome = () => {
    navigate('/')
  }
  return (
    <AppNameWrapper onClick={goHome}>
      <HeaderText>{t("ศูนย์รับเรื่องราวร้องทุกข์องค์การส่วนจังหวัดนครสวรรค์")}</HeaderText>
      {/* <HeaderText>{t("header.royal_thai_police.label")}</HeaderText> */}
      {/* <HeaderHighlightText>
        {t("header.nacrotic_suppression.label")}
      </HeaderHighlightText> */}
    </AppNameWrapper>
  );
};

// const logoUser = require("assets/images/logo_user.png");

export const UserProfile: FC = () => {
  const { t } = useTranslation("auth");
  const dispatch = useDispatch<Dispatch<any>>();
  const user = useSelector((store: any) => store.authenticate.user);
  const navigate = useNavigate();
  const isAuthenticate = useSelector(
    (store: IReducer) => store.authenticate.isAuthenticate
  );

  // console.log('me', user)

  useEffect(() => {
    if (isAuthenticate) dispatch(getUserInfo());
  }, [dispatch]);

  const onLogout = () => {
    ConfirmMessage(
      t("ท่านต้องการออกจากระบบหรือไม่"),
      t("กรุณายืนยันเพื่อออกจากระบบ") || "",
      EAlertStatus.WARNING,
    ).then((isConfirm: any) => {
      // console.log(isConfirm.value)
      if (isConfirm.value) dispatch(logout(onLogoutSuccess));
    });
  };

  const onLogoutSuccess = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <UserProfileWrapper>
      <UserProfileInfoWrapper>
        <UserNameText>{`${user?.titleName || ""} ${user?.firstName || ""} ${user?.lastName || ""
          }`}</UserNameText>
        <TextRow>
          <UserLabel>{user?.agencyText}</UserLabel>
        </TextRow>
        {/* <TextRow>
          <UserLabel>{t("logout.division.label", "ชื่อนามสกุล")}</UserLabel>
        </TextRow> */}
      </UserProfileInfoWrapper>
      {/* <UserImage src={logoUser} onClick={() => navigate("/user-profile")} /> */}
      <VerticalDivider />
      {/* <LogoutButton color={"#fff"} onClick={onLogout}>
        <LogoutIconWrapper></LogoutIconWrapper>
      </LogoutButton> */}
    </UserProfileWrapper>
  );
};
