import styled from "@emotion/styled";
import { FC } from "react";
import constants from "shared/constants/constants";

const FooterWrapper = styled.div`
  width: 100vw; //100vw
  height: ${constants.FOOTER_HEIGHT}px;
  background-color: #510089;
  position: fixed;
  bottom: 0;
  z-index: 101;
`;

const Footer: FC = () => {
  return <FooterWrapper />;
};

export default Footer;
