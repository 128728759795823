import { Footer, UnAuthenticatedHeader } from "components/layout";
import React, { Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { Dispatch } from "redux";
import { ELocalStorageKey } from "shared/enum/localstorage";
import { IReducer } from "store";
import { verifyToken } from "store/authenticate/authenticate.action";
import "assets/style/loading.css";

const Login = React.lazy(() => import("pages/Auth/Login"));
const AuthenticatedRouter = React.lazy(() => import("./Authenticated.router"));
const Home = React.lazy(() => import("pages/Home"));
const Complaint = React.lazy(() => import("pages/Complaint"));
const Track = React.lazy(() => import("pages/Track"));
const Loading = () => {
  return (
    // <div className="container d-flex h-100">
    //   <div className="row align-self-center w-100">
    //     <div className="col-1 mx-auto text-center">{"loading"}</div>
    //   </div>
    // </div>
    <figure>
      <div className="dot white"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </figure>
  );
};
const AppRouter = () => {
  const dispatch = useDispatch<Dispatch<any>>();
  const isAuthenticate = useSelector(
    (store: IReducer) => store.authenticate.isAuthenticate
  );
  const isAuthenticateLocal = localStorage.getItem(ELocalStorageKey.TOKEN);

  React.useEffect(() => {
    dispatch(verifyToken());
  }, [dispatch]);

  const isAuth = React.useMemo(() => {
    return !!isAuthenticateLocal || isAuthenticate;
  }, [isAuthenticate, isAuthenticateLocal]);

  return (
    <>
      {/* {!isAuthenticate && <UnAuthenticatedHeader />} */}
      <UnAuthenticatedHeader />
      <Suspense fallback={<Loading />}>
        <Routes>
          {/* <Route
            path="/login"
            element={isAuth ? <Navigate to="/" /> : <Login />}
          />
          <Route
            element={
              isAuth ? <AuthenticatedRouter /> : <Navigate to="/login" />
            }
          /> */}
          <Route path="/" element={<Home />} />
          <Route path="/complaint" element={<Complaint />} />
          <Route path="/track" element={<Track />} />

        </Routes>
      </Suspense>
      <Footer />
    </>
  );
};

export default AppRouter;
