export default {
  HEADER_HEIGHT: 70,
  NAVBAR_WIDTH: 75,
  NAVBAR_EXPANDED_WIDTH: 300,
  FOOTER_HEIGHT: 40,
  NAVBAR_ICON_WIDTH: 20,

  // font
  MINI_FONT: 12,
  SMALL_FONT: 14,
  MEDIUM_FONT: 16,
  LARGE_FONT: 18,
  HEADER_FONT: 24,
  TITLE_FONT: 28,

  // color
  PRIMARY_COLOR: "#510089",
  LIGHT_PRIMARY: "#C084CC",
  SECONDARY_COLOR: "#273747",//#8DA9CD
  DARK_BLUE: "#0B2545",
  BLUE: "#345995",
  LIGHT_BLUE: "#8DA9CD",
  WHITE: "#FFFFFF",
  BLACK: "#222",
  YELLOW: "#F7B801",
  RED: "#EC1F28",
  GREEN: "#408920",
  CREAM: "#EEF4ED",
  // GREY: "#99cad5",
  GREY_BLACK: "#e6e6e6",
  INVISIBLE: "#0000",
  LIGHT_RED: "#99cad5",
  IN_BLUE: "#45a3e3",
  GREY: "#ebebeb",
  // ui
  INPUT_HEIGHT: 38,

  // table
};
