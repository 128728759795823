import { StatusAlertWithHeader } from "components/ui";
import i18next from "i18next";
import { EAlertStatus } from "shared/enum/alert";
import { ELocalStorageKey } from "shared/enum/localstorage";
import { ILoginForm } from "shared/modules/authenticate";
import axios from "shared/utils/axios";
import { EAuthenticateAction, TAuthenticateType } from "./authenticate.type";

export const verifyToken = () => {
  return async (dispatch: (arg0: TAuthenticateType) => void) => {
    dispatch({ type: EAuthenticateAction.LOGIN_REQUEST });
    const token = localStorage.getItem(ELocalStorageKey.TOKEN);
    const expires = localStorage.getItem(ELocalStorageKey.EXPIRES);
    if (token && expires && new Date(parseInt(expires, 10)) > new Date()) {
      dispatch(alreadyLogin());
    } else {
      dispatch(notLogin());
    }
  };
};

const alreadyLogin = (): TAuthenticateType => {
  return {
    type: EAuthenticateAction.ALREADY_LOGIN,
  };
};

const notLogin = (): TAuthenticateType => {
  return {
    type: EAuthenticateAction.NOT_LOGIN,
  };
};

// NOTE: login
export const login = (data: ILoginForm) => {
  if (!data.email || !data.password) {
    return async (dispatch: (arg0: TAuthenticateType) => void) => {
      dispatch({ type: EAuthenticateAction.LOGIN_REQUEST });
      dispatch(
        loginFailed(i18next.t("authenticate:login_page.form.message.failed"))
      );
    };
  }
  return async (dispatch: (arg0: TAuthenticateType) => void) => {
    dispatch({ type: EAuthenticateAction.LOGIN_REQUEST });
    axios
      .post("/login", JSON.stringify(data))
      .then((response) => {
        dispatch(loginSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loginFailed(error));
      });
  };
};

const loginSuccess = (data: any): TAuthenticateType => {
  localStorage.setItem(ELocalStorageKey.TOKEN, data.token);
  localStorage.setItem(ELocalStorageKey.EXPIRES, data.expiresIn);

  return {
    type: EAuthenticateAction.LOGIN_SUCCESS,
    data,
  };
};

const loginFailed = (error: any): TAuthenticateType => {
  localStorage.removeItem(ELocalStorageKey.TOKEN);
  localStorage.removeItem(ELocalStorageKey.EXPIRES);
  StatusAlertWithHeader(
    "ชื่อผู้ใช้งานหรือรหัสผ่านผิด กรุณาตรวจสอบ",
    "",
    EAlertStatus.ERROR
  );

  return {
    type: EAuthenticateAction.LOGIN_FAILED,
    error,
  };
};

// NOTE: logout
export const logout = (cb?: Function) => {
  localStorage.clear();
  if (cb) cb();
  return {
    type: EAuthenticateAction.LOGOUT_REQUEST,
  };
};

// NOTE: /User/GetUserInfo
export const getUserInfo = () => {
  return async (dispatch: (arg0: TAuthenticateType) => void) => {
    dispatch({ type: EAuthenticateAction.GET_USER_REQUEST });
    axios
      .get("/User/GetUserInfo", {
        headers: {
          Authorization: `bearer ${localStorage.getItem(
            ELocalStorageKey.TOKEN
          )}`,
        },
      })
      .then((response) => {
        dispatch(getUserInfoSuccess(response.data));
      })
      .catch((error) => {
        dispatch(getUserInfoFailed(error));
      });
  };
};

export const getUserInfoSuccess = (data: any): TAuthenticateType => {
  return {
    type: EAuthenticateAction.GET_USER_SUCCESS,
    data,
  };
};

export const getUserInfoFailed = (error: Error): TAuthenticateType => {
  return {
    type: EAuthenticateAction.GET_USER_FAILED,
    error,
  };
};
