import i18next from "i18next";
import { EAlertStatus } from "shared/enum/alert";
const swal = require("sweetalert2");


export const StatusAlertWithHeader = (
  header: string,
  message: string,
  status: EAlertStatus
) => {
  return swal.fire({
    title: header,
    text: message,
    imageUrl: status,
    confirmButtonColor: '#510089',
    confirmButtonText: 'ตกลง',
  });
};

export const StatusAlert = (message: string, status: EAlertStatus) => {
  return swal.fire({
    title: message,
    imageUrl: status,
    // icon: status,
    timer: 10000,
    confirmButtonColor: '#510089',
    confirmButtonText: 'ตกลง',
    // buttons: {
    //   confirm: {
    //     text: i18next.t("main:button.submit", "ยืนยัน") || "",
    //     visible: false,
    //   },
    // },
  });
};

export const StatusAlertWithHTML = (title: string, html: any, status: EAlertStatus) => {
  return swal.fire({
    title: title,
    imageUrl: status,
    html: html,
    buttons: {
      confirm: {
        text: i18next.t("main:button.submit", "ยืนยัน") || "",
        visible: false,
      },
    },
  })
}


export const StatusAlertNoTimer = (message: string, status: EAlertStatus) => {
  return swal.fire({
    title: message,
    imageUrl: status,
    confirmButtonText: 'ตกลง',
    // buttons: {
    //   confirm: {
    //     text: i18next.t("main:button.submit", "ยืนยัน") || "",
    //     visible: false,
    //   },
    // },
  });
};

export const AlertMessageWithHeader = (header: string, message: string) => {
  return swal({
    title: header,
    text: message,
  });
};

export const AlertMessage = (message: string) => {
  return swal({
    text: message,
  });
};

export const ConfirmMessage = (
  title: string,
  text?: string,
  // imageUrl?: string,
  icon?: EAlertStatus,
  dangerMode: boolean = true,
  confirmButtonText: string = 'ยืนยัน',

) => {
  return swal.fire({
    title: title,
    text: text,
    // icon: icon,
    imageUrl: icon,
    dangerMode: dangerMode,
    showCancelButton: true,
    confirmButtonColor: '#510089',
    cancelButtonColor: '#d33',
    cancelButtonText: 'ยกเลิก',
    confirmButtonText: confirmButtonText,
    closeOnConfirm: false,
    reverseButtons: true
  });
};

export const DetectScreenCapture = () => {
  return swal({
    title: "ตรวจจับการบันทึกจอภาพ",
    text: "ไม่อนุญาตให้บันทึกจอภาพ",
    icon: "error",
    className: "swalDetectScreen",
    buttons: {
      confirm: {
        text: "ปิด",
        value: true,
        visible: false,
      },
    },
    timer: 5000,
  });
};

export const ConfirmIdentifySuspectId = () => {
  var form = document.createElement("div");
  form.innerHTML = `
    <img style="margin-bottom: 22px;" src=""width="100" height="100">
    <span style="font-size: 64px; color: red">!</span>
    <p style="font-size: 26px; color: red;">เลขบัตรประชาชน" นี้มีในระบบแล้ว<br>ต้องการนำ ชื่อ - สกุล เดิมที่มีในระบบมากรอกหรือไม่</p>

`;
  return swal({
    // text: "How was your experience getting help with this issue?",
    buttons: {
      cancel: {
        text: i18next.t("main:button.cancel", "ยกเลิก") || "",
        value: null,
        visible: true,
      },
      confirm: {
        text: i18next.t("main:button.submit", "ยืนยัน") || "",
        value: true,
        visible: true,
        className: "swal-button--violet",
      },
    },
    content: {
      element: form,
    },
  });
};
