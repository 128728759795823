import {
  IAuthenticateState,
  INITIAL_AUTHENTICATE_STATE,
} from "shared/modules/authenticate";
import { EAuthenticateAction, TAuthenticateType } from "./authenticate.type";

export default (
  state: IAuthenticateState = INITIAL_AUTHENTICATE_STATE,
  action: TAuthenticateType
) => {
  switch (action.type) {
    // NOTE: LOGIN
    case EAuthenticateAction.LOGIN_REQUEST:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: true,
      };
    case EAuthenticateAction.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticate: true,
        isPageLoading: false,
      };
    case EAuthenticateAction.LOGIN_FAILED:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: false,
        error: action.error,
      };
    // NOTE: LOGOUT
    case EAuthenticateAction.LOGOUT_REQUEST:
      return {
        ...state,
        isAuthenticate: true,
        isPageLoading: true,
      };
    case EAuthenticateAction.LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: false,
      };
    case EAuthenticateAction.LOGOUT_FAILED:
      return {
        ...state,
        isAuthenticate: true,
        isPageLoading: false,
      };
    // NOTE: VERIFY
    case EAuthenticateAction.ALREADY_LOGIN:
      return {
        ...state,
        isAuthenticate: true,
        isPageLoading: false,
      };
    case EAuthenticateAction.NOT_LOGIN:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: false,
      };
    // NOTE: GET_USER
    case EAuthenticateAction.GET_USER_REQUEST:
      return {
        ...state,
        isAuthenticate: true,
        isPageLoading: true,
      };
    case EAuthenticateAction.GET_USER_SUCCESS:
      return {
        ...state,
        isAuthenticate: false,
        isPageLoading: false,
        user: action.data,
      };
    case EAuthenticateAction.GET_USER_FAILED:
      return {
        ...state,
        isAuthenticate: true,
        isPageLoading: false,
      };
    default:
      return state;
  }
};
