import { IAuthenticateState } from "./authenticate.interface";

export const INITIAL_AUTHENTICATE_STATE: IAuthenticateState = {
  isPageLoading: false,
  isAuthenticate: false,
  error: undefined,
  all: {},
  me: {},
};

export const INITIAL_LOGIN_FORM = {
  email: "",
  password: "",
};

export const INITIAL_LOGIN_FORM_VALIDATE = {
  email: "",
  password: "",
};
