import styled from "@emotion/styled";
import { Divider } from "components/ui";
import React, { FC } from "react";

interface IPageHeaderProps {
  className?: string;
  isDivider?: boolean;
  children?: React.ReactNode;
}

const PageHeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: -webkit-sticky;
  position: -webkit-sticky;
  background-color: #ffffff;
  z-index: 1;
  padding: 0px 0px 20px 0px;
  font-size: 18pt;
  font-weight: bold;
`;

const PageHeaderDivider = styled(Divider)`
  margin: 0;
  margin-bottom: 16px;
`;

const PageHeader: FC<IPageHeaderProps> = ({
  children,
  className,
  isDivider = true,
}) => {
  return (
    <>
      <PageHeaderWrapper id="divToScrollTo" className={className}>
        {children}
      </PageHeaderWrapper>
      {isDivider && <PageHeaderDivider />}
    </>
  );
};

export default PageHeader;
