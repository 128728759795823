import { IAttachment, IUploadFileResponse } from "shared/interface/api.interface";
import { IAssessmentForm, IComplaintForm, IComplaintValidate, ILocationForm, IOtpForm, IPopulationForm, IPopulationValidate } from "./complaint.interface";
import { INITIAL_COORDS } from "components/ui/constant/google.map.constant";
import moment from "moment";

export const INITAIL_COMAPLAINT_STATE = {
    selected: {},
    all: [],
    error: undefined,
    isPageLoading: false,
    isButtonLoading: false,
    isTableLoading: false
}

export const INITIAL_POPULATION_FORM: IPopulationForm = {
    title_id: null,
    first_name: '',
    last_name: '',
    id_card: '',
    phone_number: '',
    email: '',
    is_anonymous: false,

    //valiable use on web
    is_policy: false,
    accept_policy: false,
}

export const INITIAL_COMPLAINT_FORM: IComplaintForm = {
    title_text: '',
    type_id: null,
    detail: '',
    accident_date: moment(new Date()),
    accident_time: moment(new Date()),

    attachment_id: [] as IUploadFileResponse[],
    attachments: [],
    fileName: null,
    newAttachments: null || [],
    fileAttactment: [] as IUploadFileResponse[],
}

export const INITIAL_LOCATION_FORM: ILocationForm = {
    house_no: '',
    alley: '',
    road: '',
    village_no: null,
    villag: '',
    subdistricts_id: null,
    districts_id: null,
    provinces_id: 60,
    zip_no: '',
    location_name: '',
    latitude: null,
    longitude: null,

    details: '',

    _latitude: INITIAL_COORDS.lat,
    _longitude: INITIAL_COORDS.lng,

}

export const INITIAL_OTP_FORM: IOtpForm = {
    code_document: '',
    id: '',
    complaint_id: '',
    ref_code: '',
    otp: ''
}

export const INITIAL_ASSESSMENT_FORM: IAssessmentForm = {
    assessment: null,
    comment: '',
}


export const INITAIL_COMAPLAINT_VALIDATE: IComplaintValidate = {
    title_text: '',
    type_id: '',
    accident_date: '',
}

export const INITAIL_POPULATION_VALIDATE: IPopulationValidate = {
    first_name: '',
    last_name: '',
    id_card: '',
    phone_number: '',
}
