import axios from 'axios';
import config from 'shared/config/app.env';

export default axios.create({
  baseURL: config.BASE_API_URL,
  timeout: 900000,
  headers: {
    'Content-Type': 'application/json;utf-8',
  },
});
