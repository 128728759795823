import styled from "@emotion/styled";
import { FC } from "react";
import constants from "shared/constants/constants";

interface IDividerProps {
  className?: string;
}

const StyledDivider = styled.div`
  border-bottom: 2px solid ${constants.GREY};
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
`;

const Divider: FC<IDividerProps> = ({ className }) => (
  <StyledDivider className={className} />
);

export default Divider;
