import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import InputTemplate from './InputTemplate';
import { styled } from 'styled-components';
import { FormControl, Grid, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const TimePickerBody = styled(TimePicker)`
    width: 100%;
    // padding-bottom: 1em !important;
    overflow: initial !important;
    padding-top: -8px !important;
    // .MuiOutlinedInput-input {
    //   padding: 16px 14px
    // }
    .Mui-disabled {
      background-color: #f5f5f5 !important;
      border-color: #9b9b9b;
    }
`;

const IconButtonWrapper = styled(IconButton)`
  height: 2.5rem;
  width: 2.5rem;
`;

interface IProps {
    label?: string;
    placeholder?: string;
    className?: string;
    ampm?: boolean;
    value?: any;
    info?: any;
    error?: string;
    required?: boolean;
    onClear?(field: string): void;
    onChange?(value: any, context: any): void;
    field?: string;
    disabled?: boolean;
}

const TimePickerField: React.FC<IProps> = ({
    label,
    placeholder,
    className,
    ampm,
    value,
    info,
    error,
    required,
    onClear,
    onChange,
    field,
    disabled
}) => {
    // console.log('value', value)
    return (
        <FormControl sx={{ width: "100%", marginTop: '8px' }}>
            <Grid container spacing={1}>
                <Grid item xs={onClear ? 10 : 12}>
                    <InputTemplate className={className} label={label} info={info} error={error} required={required}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['TimePicker']}>
                                <TimePickerBody
                                    label={label}
                                    // placeholder={placeholder}
                                    ampm={ampm}
                                    value={value}
                                    onChange={onChange}
                                // viewRenderers={{
                                //     hours: renderTimeViewClock,
                                //     minutes: renderTimeViewClock,
                                //     seconds: renderTimeViewClock,
                                // }}
                                />


                            </DemoContainer>
                        </LocalizationProvider>
                    </InputTemplate>
                </Grid>

                {onClear && field && (
                    <Grid item xs={2}>
                        <IconButtonWrapper onClick={disabled ? () => { } : () => onClear(field)}>
                            <ClearIcon />
                        </IconButtonWrapper>
                    </Grid>
                )}
            </Grid>
        </FormControl>
    );
}

export default TimePickerField;