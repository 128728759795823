import styled from "@emotion/styled";
import { LargeText } from "components/ui";
import { DefaultTFuncReturn } from "i18next";
import React, { CSSProperties, FC } from "react";
import constants from "shared/constants/constants";

interface ISectionProps {
  className?: string;
  label?: string | DefaultTFuncReturn;
  hasBorder?: boolean;
  sectionContentStyle?: CSSProperties;
  backgroundColor?: string;
  children?: React.ReactNode;
}

const SectionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const SectionLabel = styled(LargeText)``;

const SectionContent = styled.div`
/* border: ${(props: ISectionProps) =>
    props.hasBorder ? `1px solid ${constants.PRIMARY_COLOR}` : "none"};
    */
  padding: 26px;
  border-radius: 20px;
  box-shadow: ${(props) => (props.backgroundColor ? '' : 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;')};
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : "")};
`;

const Section: FC<ISectionProps> = ({
  className,
  label,
  hasBorder = true,
  sectionContentStyle,
  children,
  backgroundColor,
}) => {
  return (
    <SectionWrapper className={className}>
      {label && <SectionLabel>{label}</SectionLabel>}
      <SectionContent
        hasBorder={hasBorder}
        style={sectionContentStyle}
        backgroundColor={backgroundColor}
      >
        {children}
      </SectionContent>
    </SectionWrapper>
  );
};

export default Section;
