import { IComplaintResponse, IOtpForm, ITrackSearch } from "./track.interface"

export const INITAIL_TRACK_STATE = {
    selected: {},
    all: undefined,
    error: undefined,
    isPageLoading: false,
    isButtonLoading: false,
    isTableLoading: false
}

export const INITAIL_TRACK_SEARCH: ITrackSearch = {
    // isAnonymous: false,
    ls_phone: false,
    phone_or_code: '',

    // variable on web
    phone: '',
    code: '',
}



export const INITAIL_COMPLAINT_RESPONSE: IComplaintResponse = {
    id: '',
    code_document: '',
    recommend: null,
    process_id: null,
    title_text: '',
    detail: '',
    title_text_staff: '',
    detail_staff: '',
    type_id: null,
    type_name: '',
    topic_id: null,
    topic_name: '',
    type_id_staff: null,
    type_name_staff: '',
    topic_id_staff: null,
    topic_name_staff: '',
    process_name: '',
}

export const INITIAL_OTP_FORM: IOtpForm = {
    responseMessage: '',
    id_otp: '',
    phone_number: '',
    ref_code: '',
    otp: '',
    // complaint: { INITAIL_COMPLAINT_RESPONSE },
    // complaint_list: []
}