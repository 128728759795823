// import Button from "@mui/material/Button";
import { Button, CircularProgress } from "@mui/material";
import { StylesProvider } from "@mui/styles";
// import { StylesProvider } from '@mui/material';
import styled from "@emotion/styled";
// import { Button } from "@mui/material";
import React, { FC, SyntheticEvent, useEffect, useState } from "react";
import constants from "shared/constants/constants";
import { EButtonPreset } from "shared/enum/button";
import { LargeText, SmallText } from "./Font";

interface IButtonProps {
  className?: string;
  loading?: boolean;
  preset?: EButtonPreset;
  disabled?: boolean;
  onClick?(event: SyntheticEvent): void;
  fullWidth?: boolean;
  startIcon?: React.ReactNode;
  children: React.ReactNode;
  autoFocus?: boolean;
}

interface IStyledButtonProps {
  textcolor?: string;
  backgroundcolor?: string;
  bordercolor?: string;
  textDecoration?: string;
}

const ButtonText = styled(SmallText)`

  @media screen and (max-width: 440px) {
    font-size: 12pt !important; 
  }
`;

const StyledButton = styled(Button)`
  /* width: 150px; */
  margin: 6px;
  // width: ${(props) => (props.fullWidth ? "100%" : "180px")};
  width: max-content;
  // margin: 14px 1em !important; 
  // padding: 10px !important;
  // height: 38px;
  border-radius: 10px !important;
  background-color: ${(p: IStyledButtonProps) => p.backgroundcolor} !important;
  border: 1px solid ${(p: IStyledButtonProps) => p.bordercolor};
  /* ;; debug remove */
  > p {
    color: ${(p: IStyledButtonProps) => p.textcolor};
    text-decoration: ${(p: IStyledButtonProps) => p.textDecoration};
  }

  :hover {
    background-color: ${(p: IStyledButtonProps) =>
    p.backgroundcolor} !important;
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
  }
  cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  /* pointer-events: ${(props) => (props.disabled ? "auto" : "none")}; */
  @media screen and (max-width: 375px) {
    height: fit-content;
    word-break: break-word;
  }
`;

const CircularProgressStyles = styled(CircularProgress)`
  margin-left: 0.6rem;
`;

const SpinnerAdornment: FC = () => <CircularProgressStyles size={20} />;

const CustomButton: FC<IButtonProps> = ({
  className,
  loading = false,
  preset = EButtonPreset.PRIMARY,
  onClick,
  disabled,
  children,
  fullWidth,
  startIcon,
  autoFocus
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (loading) setIsLoading(true);
    else setIsLoading(false);
  }, [loading]);

  let color;
  let backgroundColor;
  let borderColor = constants.INVISIBLE;
  let textDecoration = "none";
  switch (preset) {
    case EButtonPreset.PRIMARY:
      color = constants.WHITE;
      backgroundColor = constants.PRIMARY_COLOR;
      break;
    case EButtonPreset.LIGHT_PRIMARY:
      color = constants.WHITE;
      backgroundColor = constants.LIGHT_PRIMARY;
      break;
    case EButtonPreset.GREEN:
      color = constants.WHITE;
      backgroundColor = constants.GREEN;
      break;
    case EButtonPreset.SECONDARY:
      color = constants.WHITE;
      // backgroundColor = constants.PRIMARY_COLOR;
      backgroundColor = constants.SECONDARY_COLOR
      break;
    case EButtonPreset.WARNING:
      color = constants.DARK_BLUE;
      backgroundColor = constants.LIGHT_RED;
      break;
    case EButtonPreset.DANGER:
      color = constants.WHITE;
      backgroundColor = constants.RED;
      break;
    case EButtonPreset.OUTLINE:
      color = constants.DARK_BLUE;
      backgroundColor = constants.WHITE;
      borderColor = constants.PRIMARY_COLOR;
      break;
    case EButtonPreset.LINK:
      color = constants.PRIMARY_COLOR;
      backgroundColor = constants.INVISIBLE;
      textDecoration = "underline";
      break;
    case EButtonPreset.IN_BLUE:
      color = constants.WHITE;
      backgroundColor = constants.IN_BLUE;
      break;
    case EButtonPreset.DISABLED:
      color = constants.BLACK;
      backgroundColor = constants.GREY_BLACK;
      break;
    case EButtonPreset.WHITE:
      color = constants.BLACK;
      backgroundColor = constants.WHITE;
      break;
    default:
      color = constants.WHITE;
      backgroundColor = constants.PRIMARY_COLOR;
      break;
  }

  return (
    <StylesProvider injectFirst>
      <StyledButton
        className={className}
        onClick={onClick}
        textcolor={color}
        bordercolor={borderColor}
        backgroundcolor={backgroundColor}
        textDecoration={textDecoration}
        disabled={(isLoading && true) || disabled}
        fullWidth={fullWidth}
        // minWidth={minWidth}
        startIcon={startIcon}
        autoFocus={autoFocus}
      >
        <ButtonText>{children}</ButtonText>
        {isLoading && <SpinnerAdornment />}
      </StyledButton>
    </StylesProvider>
  );
};

export default CustomButton;
