import * as React from 'react';
// import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { EButtonPreset } from 'shared/enum/button';
import { Button } from '.';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    zIndex: 100,
    margin: 'auto',
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .css-1fu2e3p-MuiPaper-root-MuiDialog-paper': {
        width: '100%',
        height: '75% !important',
        maxHeight: '75% !important',
    },
    '& .css-18i3v7t': {
        width: '70% !important',
        maxWidth: '70% !important',
        height: '75% !important',
        maxHeight: '75% !important',
    },
}));

const ButtonStyle = styled(Button)`
    margin: 0;
`;
interface IModalProps {
    children?: React.ReactNode;
    ModalTitle: string;
    onClose?: () => void;
    onSubmit?: () => void;
    open?: boolean;
    nameButton?: string;
    disabledButton?: boolean;
}

interface DialogTitleProps {
    children?: React.ReactNode;
    onClose: any;
}

const Modal: FC<IModalProps> = ({
    open = false,
    children,
    ModalTitle,
    onClose,
    onSubmit,
    nameButton,
    disabledButton
}) => {

    // const [open, setOpen] = React.useState(false);

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };
    // const handleClose = () => {
    //     setOpen(false);
    // };

    function BootstrapDialogTitle(props: DialogTitleProps) {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            float: 'right',
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    }

    return (
        <>
            <div>
                <BootstrapDialog maxWidth="lg"
                    onClose={onClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <BootstrapDialogTitle onClose={onClose}>
                        {ModalTitle}
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            {children}
                            {/* Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
                            consectetur ac, vestibulum at eros. */}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <ButtonStyle autoFocus onClick={onSubmit}
                            preset={!disabledButton ? EButtonPreset.DISABLED : EButtonPreset.PRIMARY}
                            disabled={!disabledButton}
                        >
                            {nameButton ? nameButton : 'บันทึก'}
                        </ButtonStyle>
                    </DialogActions>
                </BootstrapDialog>
            </div>
        </>
    )
}

export default Modal;
