import styled from "@emotion/styled";
import React, { FC } from "react";
import { ELayoutPosition } from "shared/enum/layout";

interface IFlex {
  className?: string;
  flex?: number;
  isPadding?: boolean;
  textPosition?: ELayoutPosition;
  children?: React.ReactNode;
}

interface IFormContainerProps {
  column?: number;
  className?: string;
  children?: React.ReactNode;
}

const StyledRow = styled.div<{ flex: number }>`
  flex: ${(props) => props.flex};
  display: flex;
  align-items: center;
  margin-bottom: 1em;
`;

const StyledColumn = styled.div<{
  flex: number;
  isPadding: boolean;
  textPosition: ELayoutPosition;
}>`
  &&&& {
    display: flex;
    flex: ${(props) => props.flex};
    padding: ${(props) => (props.isPadding ? "2em" : "5px")};
    padding-top: 0;
    justify-content: ${(props) => props.textPosition};
  }
`;

const FormWrapper = styled.div<{ column: number }>`
  display: grid;
  grid-template-columns: repeat(${(p: IFormContainerProps) => p.column}, 1fr);
  grid-column-gap: 4%;
  transition: height 0.25s;
  @media screen and (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 600px) {
    // grid-template-columns: repeat(1, 1fr);
    grid-template-columns: repeat(1, 100%);
  } 
`;

export const Row: FC<IFlex> = ({ className, flex = 1, children }) => {
  return (
    <StyledRow className={className} flex={flex}>
      {children}
    </StyledRow>
  );
};

export const Column: FC<IFlex> = ({
  className,
  flex = 1,
  isPadding = false,
  textPosition = ELayoutPosition.Left,
  children,
}) => {
  return (
    <StyledColumn
      className={className}
      flex={flex}
      isPadding={isPadding}
      textPosition={textPosition}
    >
      {children}
    </StyledColumn>
  );
};

export const FormContainer: FC<IFormContainerProps> = ({
  children,
  column = 4,
  className,
}) => (
  <FormWrapper className={className} column={column}>
    {children}
  </FormWrapper>
);
