import styled from "@emotion/styled";
import React, { FC } from "react";
import constants from "shared/constants/constants";

interface IPageWrapperProps {
  className?: string;
  isAuthenticate?: boolean;
  children?: React.ReactNode;
}

const StylePageWrapper = styled.div<{ isAuthenticate: boolean }>`
  // height: calc(100vh - ${constants.HEADER_HEIGHT + constants.FOOTER_HEIGHT}px);
  // overflow: auto;
  // overflow-y: unset;
  // position: relative;
  background-color: #fff;
  padding: 2rem;
  display: flex;
  flex: 1;
  margin: 2rem 4rem 4rem 4rem;
  // height: calc(100vh - 110px);
  flex-direction: column;
  border-radius: 26px;
  overflow: overlay;
  @media screen and (max-width: 600px){
    margin: 1rem 2rem 3rem 2rem;
  }
`;

const PageWrapper: FC<IPageWrapperProps> = ({
  children,
  className,
  isAuthenticate = true,
}) => {
  return (
    <StylePageWrapper isAuthenticate={isAuthenticate} className={className}>
      {children}
    </StylePageWrapper>
  );
};

export default PageWrapper;
