export default {
  //sit
  // BASE_API_URL: process.env.REACT_APP_BASE_API_URL || 'http://202.129.205.36:9003',
  // ADMIN_URL: 'http://202.129.205.36:9104/login'

  // pro
  // BASE_API_URL:
  //   process.env.REACT_APP_BASE_API_URL || "http://192.168.32.12:7101",
  // BASE_API_URL:
  //   process.env.REACT_APP_BASE_API_URL || "http://118.172.140.140:7101", // pro
  BASE_API_URL:
    process.env.REACT_APP_BASE_API_URL || "https://apicomplaint.nsnpao.go.th", // pro dns
  // ADMIN_URL: "http://118.172.140.146:7002/login", // pro
  ADMIN_URL: "https://complaintadm.nsnpao.go.th/login", // pro dns
  GIS_NODE_API_URL: "https://gisnode.nsnpao.go.th", // pro dns
};
