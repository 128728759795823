import styled from "@emotion/styled";
import _ from "lodash";
import React, { FC } from "react";
import { AppName, UserProfile } from "./HeaderComponents";
import constants from "shared/constants/constants";

const HEADER_SIZE = 70;
const HEADER_WIDTH = 75;
const HEADER_HEIGHT = constants.HEADER_HEIGHT;

const logo = require("assets/images/logo.png");

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: fixed;
  background-color: #510089;
  // width: 99vw; //100vw
  height: ${HEADER_SIZE}px;
  z-index: 101;
  // padding-left: ${HEADER_WIDTH}px;
`;
const AppIcon = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${HEADER_HEIGHT - 12}px;
  height: ${HEADER_HEIGHT - 12}px;
  padding: 6px;
`;
const ContentWrap = styled.div`
display: flex;
`;

const Authenticated: FC = () => {
  return (
    <HeaderWrapper>
      <ContentWrap>
        <AppIcon src={logo} alt="" />
        <AppName />
      </ContentWrap>
      <UserProfile />
    </HeaderWrapper>
  );
};

export default Authenticated;
