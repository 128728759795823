import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import { DefaultTFuncReturn } from "i18next";
import { ChangeEvent, FC, FocusEvent, KeyboardEvent } from "react";
import { InputSize, InputVariant } from "shared/enum/input";
import InputTemplate from "./InputTemplate";

const InputBody = styled(TextField)<{ error: boolean }>`
  width: 100%;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  ::placeholder {
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.42;
  }
  .Mui-disabled {
    background-color: #f5f5f5 !important;
    border-color: #9b9b9b;
  }
  /* > .MuiInputBase-root {
    > fieldset {
      border-color: ${(props) => (props.error ? "red" : "#0000003b")};
    }
  } */
`;

export interface IInputProps {
  label?: string | DefaultTFuncReturn;
  placeholder?: string | DefaultTFuncReturn;
  className?: string;
  error?: string;
  errorText?: string;
  info?: string;
  variant?: typeof InputVariant;
  size?: typeof InputSize;
  disabled?: boolean;
  value?: string | null;
  onChange?(event: ChangeEvent<HTMLInputElement>): void;
  onClick?: any;
  onBlur?(event: FocusEvent<HTMLInputElement>): void;
  type?: string;
  maxLength?: number;
  isMultiline?: boolean;
  inputComponent?: any;
  required?: boolean;
  onKeyPress?(event: KeyboardEvent<HTMLInputElement>): void;
  id?: string;
  fullWidth?: boolean;
  name?: string;
}

const Input: FC<IInputProps> = ({
  label,
  placeholder,
  className,
  error,
  errorText,
  info,
  size = InputSize.SMALL,
  variant = InputVariant.OUTLINED,
  disabled,
  value,
  onChange,
  onClick,
  onBlur,
  type,
  maxLength = 255,
  isMultiline,
  inputComponent,
  required,
  onKeyPress,
  id,
  fullWidth = false,
  name,
}) => {
  return (
    <InputTemplate
      className={className}
      label={label}
      info={info}
      error={error}
      errorText={errorText}
      required={required}
      id={id}
      fullWidth={fullWidth}
    >
      <InputBody
        error={error ? true : false}
        placeholder={placeholder || ""}
        disabled={disabled}
        // size={size}
        // variant={variant}
        value={value ? value : ""}
        onChange={onChange}
        onClick={onClick}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        type={type}
        inputProps={{
          maxLength: maxLength,
        }}
        multiline={isMultiline}
        InputProps={{
          inputComponent: inputComponent,
        }}
        name={name}
      />
    </InputTemplate>
  );
};

export default Input;
