import { ChangeEvent, FC, FocusEvent, KeyboardEvent } from 'react';
import TextField from '@mui/material/TextField';
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import InputTemplate from './InputTemplate';

const TextBody = styled(TextField)`
    padding-top: 8px !important;
    // padding-bottom: 1em !important;
    .Mui-disabled {
        background-color: #f5f5f5 !important;
        border-color: #9b9b9b;
    }
    .MuiOutlinedInput-input {
        padding: 16.5px 14px;
    }
    label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl{
        padding-top: 0.5em;
    }
`;
interface IInputFieldProps {
    id?: string; //outlined-basic
    className?: string;
    placeholder?: string;
    label?: string;
    error?: string;
    errorText?: string;
    disabled?: boolean;
    onChange?(event: ChangeEvent<HTMLInputElement>): void;
    onBlur?(event: FocusEvent<HTMLInputElement>): void;
    type?: string;
    required?: boolean;
    value?: string | null | number | any;
    inputComponent?: any;
    onKeyPress?(event: KeyboardEvent<HTMLInputElement>): void;
    maxLength?: number;
    isMultiline?: boolean;
    name?: string;
}

const InputField: FC<IInputFieldProps> = ({
    id,
    className,
    placeholder,
    label,
    error,
    errorText,
    disabled,
    onChange,
    onBlur,
    type,
    required,
    value,
    inputComponent,
    onKeyPress,
    maxLength,
    isMultiline,
    name
}) => {
    return (
        <InputTemplate className={className} label={label} error={error} errorText={errorText} required={required} id={id}>
            <FormControl sx={{ width: "100%", marginTop: '8px' }}>
                <TextBody
                    label={label}
                    error={error ? true : false}
                    placeholder={placeholder}
                    disabled={disabled}
                    value={value ? value : ''}
                    onChange={onChange}
                    onBlur={onBlur}
                    onKeyPress={onKeyPress}
                    type={type}
                    inputProps={{
                        maxLength: maxLength,
                    }}
                    multiline={isMultiline}
                    InputProps={{
                        inputComponent: inputComponent,
                    }}
                    name={name}
                    required={required}
                />
            </FormControl>
        </InputTemplate>
    )
}

export default InputField;