export enum EButtonPreset {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  WARNING = "warning",
  OUTLINE = "outline",
  DANGER = "danger",
  LINK = "link",
  GREEN = "green",
  IN_BLUE = "in_blue",
  DISABLED = "disabled",
  LIGHT_PRIMARY = "light_primary",
  WHITE = "white"
}

export enum EButtonColor {
  Red = "red",
  Orange = "orange",
  Yellow = "yellow",
  Olive = "olive",
  Green = "green",
  Teal = "teal",
  Blue = "blue",
  Violet = "violet",
  Purple = "purple",
  Pink = "pink",
  Brown = "brown",
  Grey = "grey",
  Black = "black",
}

export enum EButtonSize {
  Mini = "mini",
  Tiny = "tiny",
  Small = "small",
  Medium = "medium",
  Large = "large",
  Big = "big",
  Huge = "huge",
  Massive = "massive",
}

export enum ButtonType {
  Primary = "primary",
  Ghost = "ghost",
  Dashed = "dashed",
  Danger = "danger",
  Link = "link",
  Text = "text",
  Default = "default",
}
