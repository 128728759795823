import React, { FC, FocusEvent, SetStateAction } from 'react';
import styled from 'styled-components';
import MomentUtils from '@date-io/moment';
import InputTemplate from './InputTemplate';
import { MuiPickersUtilsProvider, DatePicker as MUIDatePicker } from 'material-ui-thai-datepickers';
// import { DateFormat, InputSize, InputVariant } from './enum';
import 'moment/locale/th';
import { TDateTime } from 'shared/types';
import { FormControl, Grid, IconButton, InputLabel, OutlinedInput } from '@mui/material';
// import ClearIcon from '@material-ui/icons/Clear';
// import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DateFormat } from './enum/date-picker.enum';
import moment from 'moment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import InputAdornment from '@mui/material/InputAdornment';
import InputField from './InputField';
import Input from '@mui/material/Input';
import { EDateFormat } from 'shared/enum/date-format.enum';
import { addYears } from 'date-fns'; // หรือวิธีการแก้ไขวันที่ที่คุณเลือก

const DatePickerBody = styled(MUIDatePicker)`
  width: 100%;
  padding-top: 8px !important;
  .MuiOutlinedInput-input {
  padding: 16px 14px
  }
  .Mui-disabled {
  background-color: #f5f5f5 !important;
  border-color: #9b9b9b;
  }
  label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl{
  padding-top: 0.4em;
  }
  .MuiSvgIcon-root {
    font-size: 0rem;
}

  @media screen and (max-width: 992px) {

  }

  @media screen and (max-width: 600px) {

  }
`;

const IconButtonWrapper = styled(IconButton)`
  height: 2.5rem;
  width: 2.5rem;
`;

const IconWrap = styled.div`
  position: absolute;
  text-align: right;
  right: 0.5em;
  top: 1em;
  opacity: 0.4;
`;

interface IDatePickerProps {
  label?: string;
  placeholder?: string;
  className?: string;
  error?: string;
  info?: string;
  variant?: 'standard';
  size?: 'small';
  disabled?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  value?: TDateTime;
  onChange(event: TDateTime): void;
  onBlur?(event: FocusEvent<HTMLDivElement>): void;
  format?: DateFormat | EDateFormat;
  maxDate?: TDateTime;
  minDate?: TDateTime;
  required?: boolean;
  views?: any;
  onClear?(field: string): void;
  field?: string;
  id?: string;
}

const DatePicker: FC<IDatePickerProps> = ({
  label,
  placeholder,
  className,
  error,
  info,
  size,
  variant,
  disabled,
  disableFuture,
  disablePast,
  value,
  onChange,
  onBlur,
  format,
  maxDate,
  minDate,
  required,
  views,
  onClear,
  field,
  id,
}) => {

  return (
    <InputTemplate className={className} label={label} info={info} error={error} required={required} id={id}>
      <FormControl sx={{ width: "100%", marginTop: '8px' }}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale={'th'}>
          <DatePickerBody
            error={error ? true : false}
            placeholder={placeholder}
            disabled={disabled}
            disableFuture={disableFuture}
            disablePast={disablePast}
            size={size}
            inputVariant={'outlined'}
            value={value ? moment(value) : null}
            maxDate={maxDate}
            minDate={minDate}
            onChange={onChange}
            onBlurCapture={onBlur}
            format={format}
            variant={'inline'}
            yearOffset={543}
            autoOk={true}
            views={views}
            label={label}
            required={required}
          />

          {/* <IconWrap>
            <CalendarMonthIcon />
          </IconWrap> */}
        </MuiPickersUtilsProvider>

      </FormControl>

    </InputTemplate>
  );
};

export default DatePicker;
