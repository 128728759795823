import { applyMiddleware, combineReducers, createStore } from "redux";
import thunkMiddleware from "redux-thunk";

import authenticate from "./authenticate/authenticate.reducer";
import googleMaps from "./google-maps/google-maps.reducer";
import track from "./track/track.reducer";
import complaint from "./complaint/complaint.reducer";

import { IAuthenticateState } from "shared/modules/authenticate";
import { IGoogleMapsState } from "shared/interface/google-maps.interface";
import { ITrackState } from "shared/modules/track/track.interface";
import { IComplaintState } from "shared/modules/complaint/complaint.interface";

export interface IReducer {
  authenticate: IAuthenticateState;
  googleMaps: IGoogleMapsState;
  track: ITrackState;
  complaint: IComplaintState;
}

const rootReducer = combineReducers({
  authenticate,
  googleMaps,
  track,
  complaint,
});

export default createStore(rootReducer, applyMiddleware(thunkMiddleware));
