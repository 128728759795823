import React, { FC, useEffect, useState, FocusEvent } from 'react';
import styled from 'styled-components';
import InputTemplate from './InputTemplate';
import { SelectOption, SelectOptionDisable, SelectOptionKey, SelectOptionString } from 'components/ui/type';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { FormHelperText, IconButton } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';

const SelectBody = styled(Autocomplete)`
    width:100%;
    padding-top: 8px !important;
    // padding-bottom: 1em!important;
    // margin-top:8px;
    .Mui-disabled {
        background-color: #f5f5f5 !important;
        border-color: #9b9b9b;
    }
    .MuiIconButton-root {
        padding: 0px !important;
      }
    @media screen and (max-width: 600px) {
        width: 100%;
    } 
`;

const MenuItemBody = styled(MenuItem)`
    &:hover {
        background-color: #855fac82 !important;
        border-radius: 10px;
    }
    background-color: #fff !important;
`;


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


interface ISelectProps {
    id?: string;
    label?: string;
    placeholder?: string;
    className?: string;
    error?: string;
    errorText?: string;
    info?: string;
    //   variant?: InputVariant;
    //   size?: InputSize;
    disabled?: boolean;
    value?: number | number[] | string | string[] | null;
    onChange?(value: unknown, event: unknown): void;
    // onChange?(event: SelectChangeEvent): void;
    onBlur?(event: FocusEvent<HTMLDivElement>): void;
    multiple?: boolean;
    options?: SelectOption[] | SelectOptionDisable[] | SelectOptionKey[];
    // optionString?: SelectOptionString[];
    isLoading?: boolean;
    required?: boolean;
    onModalPDF?(field: string): void;
    field?: string;
    onFocus?(): void;
    freeSolo?: boolean;
}

const SelectField: FC<ISelectProps> = ({
    id,
    label,
    placeholder,
    className,
    error,
    errorText,
    info,
    //   size = InputSize.SMALL,
    //   variant = InputVariant.OUTLINED,
    disabled,
    value,
    onChange,
    onBlur,
    multiple = false,
    options = [],
    // optionString = [],
    isLoading,
    required,
    field,
    onModalPDF,
    onFocus,
    freeSolo = false,
}) => {
    const [optionLabels, setOptionLabels] = useState<any>({});

    useEffect(() => {
        setOptionLabels(options.reduce((acc, { value, text }) => ({ ...acc, [value]: text }), {}));
    }, [options]);

    return (
        <InputTemplate className={className} label={label} error={error} required={required} id={id}>
            <FormControl sx={{ width: "100%", marginTop: '8px' }} required={required} error>
                {/* <InputLabel id="demo-multiple-name-label">{label}</InputLabel> */}
                {/* <SelectBody
                    id={id}
                    multiple={multiple}
                    value={value}
                    onChange={(newValue: unknown, _: any) => { //, newValue: any
                        if (onChange) onChange(newValue, _);
                    }}
                    input={<OutlinedInput label={label} />}
                    MenuProps={MenuProps}
                    disabled={disabled}

                >
                    {options.map((name: any) => (
                        <MenuItemBody
                            key={name.value}
                            value={name.text}
                        // style={getStyles(name, personName, theme)}
                        >
                            {name.text}
                        </MenuItemBody>
                    ))}
                </SelectBody> */}
                <SelectBody
                    placeholder={required ? placeholder + ' *' : placeholder}
                    disabled={disabled}
                    value={isLoading ? (multiple ? [] : null) : value || null}
                    onChange={(_: any, newValue: any) => {
                        if (onChange) onChange(newValue, _);
                    }}
                    onBlur={onBlur}
                    multiple={multiple}
                    options={options.map((option) => option.value)}
                    // optionString={optionString.map((option) => option.text)}
                    loading={isLoading}
                    getOptionLabel={(option: any) => optionLabels[option] || ''}
                    noOptionsText={'ไม่พบข้อมูล'}
                    renderInput={(param: any) => (
                        <TextField
                            {...param}
                            //   variant={variant}
                            //   size={size}
                            error={error ? true : false}
                            label={!value ? required ? placeholder + ' *' : placeholder : required ? label + ' *' : label}
                            placeholder={placeholder}
                        />
                    )}
                // input={<OutlinedInput label={label} />}

                />
                {/* <FormHelperText>{error}</FormHelperText> */}
            </FormControl>
        </InputTemplate>
    );
};

export default SelectField;
