export enum EAuthenticateAction {
  LOGIN_REQUEST = "LOGIN_REQUEST",
  LOGIN_SUCCESS = "LOGIN_SUCCESS",
  LOGIN_FAILED = "LOGIN_FAILED",

  LOGOUT_REQUEST = "LOGOUT_REQUEST",
  LOGOUT_SUCCESS = "LOGOUT_SUCCESS",
  LOGOUT_FAILED = "LOGOUT_FAILED",

  ALREADY_LOGIN = "ALREADY_LOGIN",
  NOT_LOGIN = "NOT_LOGIN",

  GET_USER_REQUEST = "GET_USER_REQUEST",
  GET_USER_SUCCESS = "GET_USER_SUCCESS",
  GET_USER_FAILED = "GET_USER_FAILED",
}

// NOTE: LOGIN
interface IAuthenticateLoginRequest {
  type: EAuthenticateAction.LOGIN_REQUEST;
}

interface IAuthenticateLoginResponse {
  type: EAuthenticateAction.LOGIN_SUCCESS;
  data: any;
}

interface IAuthenticateLoginFailed {
  type: EAuthenticateAction.LOGIN_FAILED;
  error: Error;
}

// NOTE: LOGOUT
interface IAuthenticateLogoutRequest {
  type: EAuthenticateAction.LOGOUT_REQUEST;
}

interface IAuthenticateLogoutResponse {
  type: EAuthenticateAction.LOGOUT_SUCCESS;
}

interface IAuthenticateLogoutFailed {
  type: EAuthenticateAction.LOGOUT_FAILED;
  error: Error;
}

// NOTE: VERIFY
interface IAuthenticateAlreadyLogin {
  type: EAuthenticateAction.ALREADY_LOGIN;
}

interface IAuthenticateNotLogin {
  type: EAuthenticateAction.NOT_LOGIN;
}

// NOTE: GET_USER
interface IAuthenticateGetUserRequest {
  type: EAuthenticateAction.GET_USER_REQUEST;
}

interface IAuthenticateGetUserResponse {
  type: EAuthenticateAction.GET_USER_SUCCESS;
  data: any; //IAuthenticateUser
}

interface IAuthenticateGetUserFailed {
  type: EAuthenticateAction.GET_USER_FAILED;
  error: Error;
}

export type TAuthenticateType =
  | IAuthenticateLoginRequest
  | IAuthenticateLoginResponse
  | IAuthenticateLoginFailed
  | IAuthenticateLogoutRequest
  | IAuthenticateLogoutResponse
  | IAuthenticateLogoutFailed
  | IAuthenticateAlreadyLogin
  | IAuthenticateNotLogin
  | IAuthenticateGetUserRequest
  | IAuthenticateGetUserResponse
  | IAuthenticateGetUserFailed;
