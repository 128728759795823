import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FC } from 'react';
import { styled } from 'styled-components';

const AccordionContainer = styled.div`
    margin-top: 20px;
`;

const AccordionWrapper = styled(Accordion) <{ width?: string }>`
    width: ${(props) => props.width ? props.width : '100%'}  ;
    padding: 0px 0px;
    // margin: 0.4em 0em ;
    border-radius: 9px!important;
    // background-color: #f9f7ff;
     // box-shadow: none !important;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    &:before {
        height: 0px;
      }   
}

`;

const AccordionSummaryWrapper = styled(AccordionSummary)`
    display: flex !important;
    padding: 0 1em !important;
`;

const HeaderText = styled.h2`
    margin: 0px;
    // margin: 0px 0 !important;
`;

const AccordionDetailsWrapper = styled(AccordionDetails)`
    padding: 0px 40px;
    margin-bottom: 20px;

    @media screen and (max-width: 992px) {
        
    }

    @media screen and (max-width: 600px) {
        padding: 0px 16px;
        margin-bottom: 10px;
    }
`;

const TypographyWrapper = styled(Typography)`
    // padding: 6px 25px;
    // margin-bottom: 30px;
    // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    // border-radius: 12px;
`;

interface IBasicAccordion {
    header: string;
    children?: React.ReactNode;
    width?: string;
    expanded?: any;
    onChange?(item: any): void;
    defaultExpanded?: any;
}

const BasicAccordion: FC<IBasicAccordion> = ({
    header,
    children,
    width,
    expanded,
    onChange,
    defaultExpanded
}) => {
    return (
        <AccordionContainer>
            <AccordionWrapper width={width} expanded={expanded} onChange={onChange} defaultExpanded={defaultExpanded}>
                <AccordionSummaryWrapper
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <HeaderText>{header}</HeaderText>
                </AccordionSummaryWrapper>
                <AccordionDetailsWrapper>
                    <TypographyWrapper>
                        {children}
                    </TypographyWrapper>
                </AccordionDetailsWrapper>
            </AccordionWrapper>
        </AccordionContainer>
    )
}

export default BasicAccordion;