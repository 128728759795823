import { IDTOPlaceNearbyResponse } from 'shared/interface/google-maps.interface';

export enum EGoogleMapsAction {
  GET_PLACE_NAME_REQUEST = 'GET_PLACE_NAME_REQUEST',
  GET_PLACE_NAME_SUCCESS = 'GET_PLACE_NAME_SUCCESS',
  GET_PLACE_NAME_FAILED = 'GET_PLACE_NAME_FAILED',
  GET_PLACE_NEARBY_REQUEST = 'GET_PLACE_NEARBY_REQUEST',
  GET_PLACE_NEARBY_SUCCESS = 'GET_PLACE_NEARBY_SUCCESS',
  GET_PLACE_NEARBY_FAILED = 'GET_PLACE_NEARBY_FAILED',
}

interface IGetPlaceNameRequest {
  type: EGoogleMapsAction.GET_PLACE_NAME_REQUEST;
}

interface IGetPlaceNameSuccess {
  type: EGoogleMapsAction.GET_PLACE_NAME_SUCCESS;
  placeName: string;
}

interface IGetPlaceNameFailed {
  type: EGoogleMapsAction.GET_PLACE_NAME_FAILED;
  error?: Error;
}

// ;; get place nearby
interface IGetPlaceNearbyRequest {
  type: EGoogleMapsAction.GET_PLACE_NEARBY_REQUEST;
}

interface IGetPlaceNearbySuccess {
  type: EGoogleMapsAction.GET_PLACE_NEARBY_SUCCESS;
  data: IDTOPlaceNearbyResponse[];
}

interface IGetPlaceNearbyFailed {
  type: EGoogleMapsAction.GET_PLACE_NEARBY_FAILED;
  error?: Error;
}

export type TGoogleMapsActionTypes =
  | IGetPlaceNameRequest
  | IGetPlaceNameSuccess
  | IGetPlaceNameFailed
  | IGetPlaceNearbyRequest
  | IGetPlaceNearbySuccess
  | IGetPlaceNearbyFailed;
