import { INITAIL_TRACK_STATE } from "shared/modules/track/track.constant";
import { ETrackAction } from "./track.type";

export default (state = INITAIL_TRACK_STATE, action: any) => {
    switch (action.type) {
        case ETrackAction.TRACK_SEARCH_REQUEST:
            return {
                ...state,
                isButtonLoading: true,
                isPageLoading: true
            };
        case ETrackAction.TRACK_SEARCH_SUCCESS:
            return {
                ...state,
                all: action.data,
                isButtonLoading: false,
                isPageLoading: false
            };
        case ETrackAction.TRACK_SEARCH_FAILED:
            return {
                ...state,
                all: [],
                error: action.error,
                isButtonLoading: false,
                isPageLoading: FontFaceSetLoadEvent
            };
        case ETrackAction.SEND_OTP_REQUEST:
            return {
                ...state,
                isButtonLoading: true,
                isPageLoading: true,
            };
        case ETrackAction.SEND_OTP_SUCCESS:
            return {
                ...state,
                all: action.data,
                isButtonLoading: false,
                isPageLoading: false,
            };
        case ETrackAction.SEND_OTP_FAILED:
            return {
                ...state,
                all: [],
                error: action.error,
                isButtonLoading: false,
                isPageLoading: false,
            };
        case ETrackAction.CONFIRM_OTP_REQUEST:
            return {
                ...state,
                isButtonLoading: true,
                isPageLoading: true,
            };
        case ETrackAction.CONFIRM_OTP_SUCCESS:
            return {
                ...state,
                all: action.data,
                isButtonLoading: false,
                isPageLoading: false,
            };
        case ETrackAction.CONFIRM_OTP_FAILED:
            return {
                ...state,
                all: [],
                error: action.error,
                isButtonLoading: false,
                isPageLoading: false,
            };
        case ETrackAction.TRACK_ASSESSMENT_REQUEST:
            return {
                ...state,
                isButtonLoading: true,
                isPageLoading: true,
            };
        case ETrackAction.TRACK_ASSESSMENT_SUCCESS:
            return {
                ...state,
                all: action.data,
                isButtonLoading: false,
                isPageLoading: false,
            };
        case ETrackAction.TRACK_ASSESSMENT_FAILED:
            return {
                ...state,
                all: [],
                error: action.error,
                isButtonLoading: false,
                isPageLoading: false,
            };

        case ETrackAction.DOWNLOAD_FILE_REQUEST:
            return {
                ...state,
                isButtonLoading: false,
                isPageLoading: true,
            };
        case ETrackAction.DOWNLOAD_FILE_SUCCESS:
            return {
                ...state,
                // selected: action.data,
                selected: {},
                isButtonLoading: false,
                isPageLoading: false,
            };
        case ETrackAction.DOWNLOAD_FILE_FAILED:
            return {
                ...state,
                selected: {},
                error: action.error,
                isButtonLoading: false,
                isPageLoading: false,
            };
        case ETrackAction.DELETE_FILE_REQUEST:
            return {
                ...state,
                isButtonLoading: false,
                isPageLoading: true,
            };
        case ETrackAction.DELETE_FILE_SUCCESS:
            return {
                ...state,
                // selected: action.data,
                selected: {},
                isButtonLoading: false,
                isPageLoading: false,
            };
        case ETrackAction.DELETE_FILE_FAILED:
            return {
                ...state,
                selected: {},
                error: action.error,
                isButtonLoading: false,
                isPageLoading: false,
            };
        default:
            return state;

    }
}