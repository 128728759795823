import styled from "@emotion/styled";
// import styled from 'styled-components';
import { FC } from "react";
import constants from "shared/constants/constants";
import { AppName } from "./HeaderComponents";
import Logo from 'assets/images/jaray_logo.svg'
import { ImageListItem } from '@mui/material';
import { useNavigate } from "react-router-dom";
// import Logo from "../../../assets/images/npao_logo.jpg";

const logo = require("assets/images/logo.png");
const logoDe = require("assets/images/defund_logo_th.jpg");

const HEADER_HEIGHT = constants.HEADER_HEIGHT;

const HeaderWrapper = styled.div`
  display: flex;
  background-color: #510089;
  // width: 100vw; //
  height: ${HEADER_HEIGHT}px;
  z-index: 101;
  cursor: pointer;
  justify-content: space-between;
`;

const ContentHead = styled.div`
  display: flex;
`;

const AppIcon = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${HEADER_HEIGHT - 12}px;
  height: ${HEADER_HEIGHT - 12}px;
  padding: 6px;
`;

const UnAuthenticated: FC = () => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate('/')
  }
  return (
    <HeaderWrapper onClick={goHome}>
      <ContentHead>
        <AppIcon src={logo} alt="" />
        <AppName />
      </ContentHead>

      <div style={{ backgroundColor: "#ffffff", padding: "0 0.5rem" }}>
        <AppIcon src={logoDe} alt="" style={{ width: "4rem" }} />
      </div>
    </HeaderWrapper>
  );
};

export default UnAuthenticated;
