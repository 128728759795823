import styled from "@emotion/styled";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Dispatch } from "redux";
import constants from "shared/constants/constants";

const NAVBAR_WIDTH = constants.NAVBAR_WIDTH;
const NAVBAR_HEIGHT = constants.HEADER_HEIGHT;
const NAVBAR_EXPANDED_WIDTH = constants.NAVBAR_EXPANDED_WIDTH;

interface INavbarBodyProps {
  expand?: boolean;
}

interface IMenuBar {
  link: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  icon: string;
  activeIcon: string;
  label: string;
}

const NavbarItemListWrapper = styled.div`
  margin-top: ${NAVBAR_HEIGHT * 2}px;
  width: 100%;
`;

const NavbarBody = styled.div`
  position: fixed;
  height: 100vh;
  background-color: #2c2e3d;
  overflow-y: hidden;
  overflow-x: hidden;
  &:hover {
    overflow-y: overlay;
  }
`;

const NavbarItemIcon = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${NAVBAR_WIDTH}px;
  height: ${NAVBAR_HEIGHT}px;
`;

const NavbarItem = styled.span`
  position: absolute;
  left: ${NAVBAR_WIDTH}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${NAVBAR_EXPANDED_WIDTH - NAVBAR_WIDTH}px;
  height: ${NAVBAR_HEIGHT}px;
  padding-left: 8px;
  color: white;
`;

const NavbarItemWrapper = styled(Link)<{ isactive?: number }>`
  display: flex;
  width: 100%;
  height: ${NAVBAR_HEIGHT}px;

  ${NavbarItemIcon} {
    padding: 18px; // 22
  }

  &:hover {
    background-color: #404359;
  }
  ${(props) => props.isactive && "background-color: #404359"};
`;

const NavbarHeader = styled(NavbarItemWrapper)`
  position: fixed;
  width: ${NAVBAR_WIDTH}px;
  background-color: #2c2e3d;
  z-index: 10;
  ${NavbarItemIcon} {
    padding: 10px;
  }
`;

const NavbarMenu = styled(NavbarHeader)`
  top: ${NAVBAR_HEIGHT}px;
  border-bottom: 1px solid #6d6d77;
  ${NavbarItemIcon} {
    padding: 22px;
  }
`;

const MenuText = styled.p`
  margin: 0;
  font-size: 24px;
  user-select: none;
`;

const MenuTextHeader = styled(MenuText)`
  font-size: 18px;
`;

const HeaderText = styled(MenuTextHeader)`
  height: 1em;
`;

const HeaderHighlightText = styled(HeaderText)`
  color: #f7b801;
`;

// ${NavbarBody} {
//   width: ${(p: INavbarBodyProps) =>
//     p.expand ? NAVBAR_EXPANDED_WIDTH : NAVBAR_WIDTH}px;
//   transition: width 0.3s;
// }

// ${NavbarItem} {
//   pointer-events: ${(p: INavbarBodyProps) => (p.expand ? "all" : "none")};
// }

// ${MenuText} {
//   opacity: ${(p: INavbarBodyProps) => (p.expand ? 1 : 0)};
//   transition: opacity 0.15s;
// }

// ${NavbarHeader}, ${NavbarMenu} {
//   width: ${(p: INavbarBodyProps) =>
//     p.expand ? NAVBAR_EXPANDED_WIDTH : NAVBAR_WIDTH}px;
//   transition: width 0.3s;
// }
const NavbarWrapper = styled.div`
  display: flex;
  width: ${(p: INavbarBodyProps) =>
    p.expand ? NAVBAR_EXPANDED_WIDTH : NAVBAR_WIDTH}px;
  transition: width 0.3s;
  z-index: 10;

  ${NavbarBody} {
    width: ${(p: INavbarBodyProps) =>
      p.expand ? NAVBAR_EXPANDED_WIDTH : NAVBAR_WIDTH}px;
    transition: width 0.3s;
  }

  ${NavbarItem} {
    pointer-events: ${(p: INavbarBodyProps) => (p.expand ? "all" : "none")};
  }

  ${MenuText} {
    opacity: ${(p: INavbarBodyProps) => (p.expand ? 1 : 0)};
    transition: opacity 0.15s;
  }

  ${NavbarHeader}, ${NavbarMenu} {
    width: ${(p: INavbarBodyProps) =>
      p.expand ? NAVBAR_EXPANDED_WIDTH : NAVBAR_WIDTH}px;
    transition: width 0.3s;
  }
`;

const Navbar: FC = () => {
  const { t } = useTranslation("main");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<Dispatch<any>>();

  const [expand, setExpand] = useState(false);
  const HOME_PAGE = "/";
  const DATA_SCIENCE = "/data-science";
  const activeMenu: string =
    location.pathname === "/"
      ? HOME_PAGE
      : `/${location.pathname.split("/")[1]}` === DATA_SCIENCE
      ? `${location.pathname}`
      : `/${location.pathname.split("/")[1]}`;

  return (
    <NavbarWrapper expand={expand}>
      <NavbarBody>
        <NavbarHeader to={""}>
          <NavbarItemIcon src={""} />
          <NavbarItem>
            <HeaderText>{t("header.royal_thai_police_th.label")}</HeaderText>
            <HeaderText>{t("header.royal_thai_police.label")}</HeaderText>
            <HeaderHighlightText>
              {t("header.nacrotic_suppression.label")}
            </HeaderHighlightText>
          </NavbarItem>
        </NavbarHeader>
      </NavbarBody>
    </NavbarWrapper>
  );
};

export default Navbar;
