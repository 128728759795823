import styled from "@emotion/styled";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { DefaultTFuncReturn } from "i18next";
import { ChangeEvent, FC, FocusEvent } from "react";
import constants from "shared/constants/constants";
import { LargeText } from "./Font";
import InputTemplate from "./InputTemplate";
import { FormControl } from "@mui/material";

const TextAreaTemplate = styled(InputTemplate)`
    `;

interface ITextAreaProps {
  className?: string;
  label?: string | DefaultTFuncReturn;
  placeholder?: string | DefaultTFuncReturn;
  value?: string | null;
  resizable?: boolean;
  disabled?: boolean;
  minRows?: number;
  rowsMax?: number;
  maxLength?: number;
  error?: string;
  info?: string;
  onChange?(event: ChangeEvent<HTMLTextAreaElement>): void;
  onBlur?(event: FocusEvent<HTMLTextAreaElement>): void;
  required?: boolean;
  id?: string;
  labelremark?: string;
}

const TextAreaBody = styled(TextareaAutosize)`
  max-height: 200px !important;
  overflow: unset !important;
  cursor: auto;
  /* margin-bottom: 0px; */
  resize: ${(props: ITextAreaProps) => (props.resizable ? "" : "none")};
  padding: 10.5px 14px;
  border-color: ${(props: ITextAreaProps) =>
    props.error ? constants.RED : "rgba(0, 0, 0, 0.23)"};
  border-radius: 4px;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: rgba(0, 0, 0, 0.87);
  ::placeholder {
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.42;
  }
  &:focus {
    outline-color: ${(props: ITextAreaProps) =>
    props.error ? constants.RED : "#3F51B5"};
  }
  @media screen and (max-width: 992px) {
    width: 87%;
  }
`;

const RemarkLabel = styled(LargeText)`
  color: red;
`;

const TextArea: FC<ITextAreaProps> = ({
  className,
  label,
  placeholder,
  value,
  resizable,
  disabled,
  minRows,
  rowsMax,
  maxLength,
  error,
  info,
  onChange,
  onBlur,
  required,
  id,
  labelremark,
}) => {
  return (
    <FormControl sx={{ width: "100%", marginTop: '8px' }}>
      <TextAreaTemplate
        className={className}
        label={label}
        labelremark={labelremark}
        error={error}
        info={info}
        required={required}
        id={id}
      >
        <TextAreaBody
          error={error}
          minRows={minRows}
          rowsMax={rowsMax}
          placeholder={placeholder || ""}
          disabled={disabled}
          maxLength={maxLength}
          value={value ? value : ""}
          resizable={resizable}
          onChange={onChange}
          onBlur={onBlur}
        />
      </TextAreaTemplate>
    </FormControl>
  );
};

export default TextArea;
