import { INITAIL_COMAPLAINT_STATE } from "shared/modules/complaint/complaint.constant";
import { EComplaintAction } from "./complaint.type";

export default (state = INITAIL_COMAPLAINT_STATE, action: any) => {
    switch (action.type) {
        case EComplaintAction.COMPLAINT_REQUEST:
            return {
                ...state,
                isButtonLoading: true,
                isPageLoading: true,
            };
        case EComplaintAction.COMPLAINT_SUCCESS:
            return {
                ...state,
                all: action.data,
                isButtonLoading: false,
                isPageLoading: false,
            };
        case EComplaintAction.COMPLAINT_FAILED:
            return {
                ...state,
                all: [],
                error: action.error,
                isButtonLoading: false,
                isPageLoading: false,
            };
        case EComplaintAction.SEND_OTP_REQUEST:
            return {
                ...state,
                isButtonLoading: true,
                isPageLoading: true,
            };
        case EComplaintAction.SEND_OTP_SUCCESS:
            return {
                ...state,
                all: action.data,
                isButtonLoading: false,
                isPageLoading: false,
            };
        case EComplaintAction.SEND_OTP_FAILED:
            return {
                ...state,
                all: [],
                error: action.error,
                isButtonLoading: false,
                isPageLoading: false,
            };
        case EComplaintAction.CONFIRM_OTP_REQUEST:
            return {
                ...state,
                isButtonLoading: true,
                isPageLoading: true,
            };
        case EComplaintAction.CONFIRM_OTP_SUCCESS:
            return {
                ...state,
                all: action.data,
                isButtonLoading: false,
                isPageLoading: false,
            };
        case EComplaintAction.CONFIRM_OTP_FAILED:
            return {
                ...state,
                all: [],
                error: action.error,
                isButtonLoading: false,
                isPageLoading: false,
            };
        case EComplaintAction.COMPLAINT_ASSESSMENT_REQUEST:
            return {
                ...state,
                isButtonLoading: true,
                isPageLoading: true,
            };
        case EComplaintAction.COMPLAINT_ASSESSMENT_SUCCESS:
            return {
                ...state,
                all: action.data,
                isButtonLoading: false,
                isPageLoading: false,
            };
        case EComplaintAction.COMPLAINT_ASSESSMENT_FAILED:
            return {
                ...state,
                all: [],
                error: action.error,
                isButtonLoading: false,
                isPageLoading: false,
            };
        case EComplaintAction.SEND_EMAIL_REQUEST:
            return {
                ...state,
                isButtonLoading: true,
                isPageLoading: true,
            };
        case EComplaintAction.SEND_EMAIL_SUCCESS:
            return {
                ...state,
                all: action.data,
                isButtonLoading: false,
                isPageLoading: false,
            };
        case EComplaintAction.SEND_EMAIL_FAILED:
            return {
                ...state,
                all: [],
                error: action.error,
                isButtonLoading: false,
                isPageLoading: false,
            };
        default:
            return state;

    }
}