import { IOtpForm } from "shared/modules/track/track.interface";

export enum ETrackAction {
    TRACK_SEARCH_REQUEST = 'TRACK_SEARCH_REQUEST',
    TRACK_SEARCH_SUCCESS = 'TRACK_SEARCH_SUCCESS',
    TRACK_SEARCH_FAILED = 'TRACK_SEARCH_FAILED',

    SEND_OTP_REQUEST = 'SEND_OTP_REQUEST',
    SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS',
    SEND_OTP_FAILED = 'SEND_OTP_FAILED',

    CONFIRM_OTP_REQUEST = 'CONFIRM_OTP_REQUEST',
    CONFIRM_OTP_SUCCESS = 'CONFIRM_OTP_SUCCESS',
    CONFIRM_OTP_FAILED = 'CONFIRM_OTP_FAILED',

    TRACK_ASSESSMENT_REQUEST = 'TRACK_ASSESSMENT_REQUEST',
    TRACK_ASSESSMENT_SUCCESS = 'TRACK_ASSESSMENT_SUCCESS',
    TRACK_ASSESSMENT_FAILED = 'TRACK_ASSESSMENT_FAILED',

    DOWNLOAD_FILE_REQUEST = 'DOWNLOAD_FILE_REQUEST',
    DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS',
    DOWNLOAD_FILE_FAILED = 'DOWNLOAD_FILE_FAILED',

    DELETE_FILE_REQUEST = 'DELETE_FILE_REQUEST',
    DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS',
    DELETE_FILE_FAILED = 'DELETE_FILE_FAILED',
}


interface ITrackSearchRequest {
    type: ETrackAction.TRACK_SEARCH_REQUEST;
}

interface ITrackSearchSuccess {
    type: ETrackAction.TRACK_SEARCH_SUCCESS;
    data: any;
}

interface ITrackSearchFailed {
    type: ETrackAction.TRACK_SEARCH_FAILED;
    error: Error
}

interface ISendOtpRequest {
    type: ETrackAction.SEND_OTP_REQUEST;
}

interface ISendOtpSuccess {
    type: ETrackAction.SEND_OTP_SUCCESS;
    data: any;
}

interface ISendOtpFailed {
    type: ETrackAction.SEND_OTP_FAILED;
    error: Error
}

interface IConfirmOtpRequest {
    type: ETrackAction.CONFIRM_OTP_REQUEST;
}

interface IConfirmOtpSuccess {
    type: ETrackAction.CONFIRM_OTP_SUCCESS;
    data: any;
}

interface IConfirmOtpFailed {
    type: ETrackAction.CONFIRM_OTP_FAILED;
    error: Error
}

interface ITrackAssessmentRequest {
    type: ETrackAction.TRACK_ASSESSMENT_REQUEST;
}

interface ITrackAssessmentSuccess {
    type: ETrackAction.TRACK_ASSESSMENT_SUCCESS;
    data: any;
}

interface ITrackAssessmentFailed {
    type: ETrackAction.TRACK_ASSESSMENT_FAILED;
    error: Error
}

interface IDownloadFileRequest {
    type: ETrackAction.DOWNLOAD_FILE_REQUEST
}

interface IDownloadFileSuccess {
    type: ETrackAction.DOWNLOAD_FILE_SUCCESS;
    data: any;
}

interface IDownloadFileFailed {
    type: ETrackAction.DOWNLOAD_FILE_FAILED;
    error: Error;
}


interface IDeleteFileRequest {
    type: ETrackAction.DELETE_FILE_REQUEST
}

interface IDeleteFileSuccess {
    type: ETrackAction.DELETE_FILE_SUCCESS;
    data: any;
}

interface IDeleteFileFailed {
    type: ETrackAction.DELETE_FILE_FAILED;
    error: Error;
}


export type TTrackSearchType =
    | ITrackSearchRequest
    | ITrackSearchSuccess
    | ITrackSearchFailed
    | ISendOtpRequest
    | ISendOtpSuccess
    | ISendOtpFailed
    | IConfirmOtpRequest
    | IConfirmOtpSuccess
    | IConfirmOtpFailed
    | ITrackAssessmentRequest
    | ITrackAssessmentSuccess
    | ITrackAssessmentFailed
    | IDownloadFileRequest
    | IDownloadFileSuccess
    | IDownloadFileFailed
    | IDeleteFileRequest
    | IDeleteFileSuccess
    | IDeleteFileFailed
    ;