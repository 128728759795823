import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import AppRouter from "router/UnAuthenticated.router";
import "shared/utils/axios";
import store from "store";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Helmet } from "react-helmet";


const AppWithRouter: React.FC = () => {

  const loaded = React.useRef(false);
  function loadScript(src: string, position: HTMLElement | null, id: string) {
    if (!position) {
      return;
    }
    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
  }
  if (typeof window !== 'undefined' && !loaded.current) {
    if (!!!document.getElementById('map-container')) {

      if (!document.querySelector('.map-container')) {
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=AIzaSyDwKs5XAmH0wjPpvIQYyfB7flm0Et0EzqU&libraries=geocoder,geometry,drawing,places,visualization&language=th`,
          document.querySelector('head'),
          'map-container',
        );
      }
    }
  }


  return (
    <Provider store={store}>
      {/* <Helmet>
        <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDwKs5XAmH0wjPpvIQYyfB7flm0Et0EzqU&libraries=geocoder,geometry,drawing,places,visualization&language=th" type="text/javascript" />
      </Helmet> */}
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </Provider>
  );
};

ReactDOM.render(<AppWithRouter />, document.getElementById("root"));

reportWebVitals();
