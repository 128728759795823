import React, { FC, FocusEvent } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputTemplate from './InputTemplate';
import styled from 'styled-components';
import { LargeText } from './Font';
import { RadioOption } from './type/radio.type';


interface IProps {
    label?: string;
    className?: string;
    error?: string;
    info?: string;
    disabled?: boolean;
    value?: number | number[] | string | boolean | null;
    options?: any;
    onChange(value: unknown, event: unknown): void;
    onBlur?(_event: FocusEvent<HTMLDivElement>): void;
    required?: boolean;
}

const RadioUser: FC<IProps> = ({
    label,
    className,
    error,
    info,
    disabled,
    value,
    options,
    onChange,
    onBlur,
    required
}) => {
    return (
        // <InputTemplate className={className} label={label} info={info} error={error} required={required}>
        <RadioGroup row value={value} onChange={onChange} onBlur={onBlur}>
            {options &&
                options.map((option: any) => (
                    <FormControlLabel
                        value={option.value}
                        control={<Radio color="primary" />}
                        label={option.label}
                        labelPlacement="end"
                        key={option.value}
                        disabled={disabled}
                    />
                ))}
        </RadioGroup>
        // </InputTemplate>
    );
};
export default RadioUser;
