import styled from "@emotion/styled";
import { StylesProvider } from "@mui/styles";
import { DefaultTFuncReturn } from "i18next";
import React, { FC } from "react";
import constants from "shared/constants/constants";
import { LargeText, MediumText } from "./Font";

const InputWrapper = styled.div<{ fullWidth: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.fullWidth ? "100%" : "")};
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 0px;
  margin-top: 0em;
`;

const InputLabel = styled(LargeText)`
  font-weight: bold;
`;

const InputLabelRemark = styled(LargeText)`
  padding-left: 0.5em;
  color: red;
`;

const RequiredLabel = styled(InputLabel)`
  color: red;
  margin-right: 4px;
  font-size: 26pt;
  margin-top: -0.5em;
`;

const InputError = styled(MediumText)`
  min-height: 24px;
  height: auto;
  font-size: 12pt;
  color: ${constants.RED};
  margin-bottom: 1em;

  @media screen and (max-width: 851px){
    min-height: 0px;
    margin-bottom: 1em;
  }
  @media screen and (max-width: 332px){
    min-height: 0px;
    font-size: 10pt !important;
  }
  @media screen and (max-width: 300px){
    min-height: 0px;
    font-size: 8pt !important;
    margin-bottom: 2.5em;
  }

`;

const InputErrorText = styled(MediumText)`
  min-height: 24px;
  height: auto;
  font-size: 12pt !important;
  color: ${constants.RED};
  margin-top: -1em;

  @media screen and (max-width: 800px){
    margin-bottom: 0.6rem;
  } 
  @media screen and (max-width: 375px) {
    font-size: 10pt !important;
    margin-bottom: 2em;
  } 
  @media screen and (max-width: 305px) {
    font-size: 8pt !important;
    margin-bottom: 3em;
  } 
`;

const InputInfo = styled(MediumText)`
  color: ${constants.GREY};
`;

interface IInputTemplateProps {
  label?: string | DefaultTFuncReturn;
  className?: string;
  error?: string;
  errorText?: string;
  info?: string;
  required?: boolean;
  id?: string;
  fullWidth?: boolean;
  labelremark?: string;
  children?: React.ReactNode;
}

const InputTemplate: FC<IInputTemplateProps> = ({
  label,
  className,
  error,
  errorText,
  info,
  required,
  children,
  id,
  fullWidth = true,
  labelremark,
}) => {
  return (
    <StylesProvider injectFirst>
      <InputWrapper className={className} id={id} fullWidth={fullWidth}>
        <LabelWrapper>
          {/* {required && ( */}
          <RequiredLabel className="RequiredLabel">{required ? '*' : ''}</RequiredLabel>
          {/* )} */}
          {/*  {label && <InputLabel className="InputLabel">{label}</InputLabel>}
          {labelremark && (
            <InputLabelRemark className="InputLabel">
              {labelremark}
            </InputLabelRemark>
          )}*/}
        </LabelWrapper>
        {children}
        <InputError className="InputError">{error}</InputError>
        {errorText && <InputErrorText className="InputErrorText">{errorText}</InputErrorText>}
        {/* {info && <InputInfo>{info}</InputInfo>} */}
      </InputWrapper>
    </StylesProvider>
  );
};

export default InputTemplate;
