export enum EComplaintAction {
    COMPLAINT_REQUEST = 'COMPLAINT_REQUEST',
    COMPLAINT_SUCCESS = 'COMPLAINT_SUCCESS',
    COMPLAINT_FAILED = 'COMPLAINT_FAILED',

    SEND_OTP_REQUEST = 'SEND_OTP_REQUEST',
    SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS',
    SEND_OTP_FAILED = 'SEND_OTP_FAILED',

    CONFIRM_OTP_REQUEST = 'CONFIRM_OTP_REQUEST',
    CONFIRM_OTP_SUCCESS = 'CONFIRM_OTP_SUCCESS',
    CONFIRM_OTP_FAILED = 'CONFIRM_OTP_FAILED',

    COMPLAINT_ASSESSMENT_REQUEST = 'COMPLAINT_ASSESSMENT_REQUEST',
    COMPLAINT_ASSESSMENT_SUCCESS = 'COMPLAINT_ASSESSMENT_SUCCESS',
    COMPLAINT_ASSESSMENT_FAILED = 'COMPLAINT_ASSESSMENT_FAILED',

    SEND_EMAIL_REQUEST = 'SEND_EMAIL_REQUEST',
    SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS',
    SEND_EMAIL_FAILED = 'SEND_EMAIL_FAILED',
}

interface IComplaintRequest {
    type: EComplaintAction.COMPLAINT_REQUEST;
}

interface IComplaintSuccess {
    type: EComplaintAction.COMPLAINT_SUCCESS;
    data: any;
}

interface IComplaintFailed {
    type: EComplaintAction.COMPLAINT_FAILED;
    error: Error
}


interface ISendOtpRequest {
    type: EComplaintAction.SEND_OTP_REQUEST;
}

interface ISendOtpSuccess {
    type: EComplaintAction.SEND_OTP_SUCCESS;
    data: any;
}

interface ISendOtpFailed {
    type: EComplaintAction.SEND_OTP_FAILED;
    error: Error
}

interface IConfirmOtpRequest {
    type: EComplaintAction.CONFIRM_OTP_REQUEST;
}

interface IConfirmOtpSuccess {
    type: EComplaintAction.CONFIRM_OTP_SUCCESS;
    data: any;
}

interface IConfirmOtpFailed {
    type: EComplaintAction.CONFIRM_OTP_FAILED;
    error: Error
}

interface IComplaintAssessmentRequest {
    type: EComplaintAction.COMPLAINT_ASSESSMENT_REQUEST;
}

interface IComplaintAssessmentSuccess {
    type: EComplaintAction.COMPLAINT_ASSESSMENT_SUCCESS;
    data: any;
}

interface IComplaintAssessmentFailed {
    type: EComplaintAction.COMPLAINT_ASSESSMENT_FAILED;
    error: Error
}

interface ISendEmailRequest {
    type: EComplaintAction.SEND_EMAIL_REQUEST;
}

interface ISendEmailSuccess {
    type: EComplaintAction.SEND_EMAIL_SUCCESS;
    data: any;
}

interface ISendEmailFailed {
    type: EComplaintAction.SEND_EMAIL_FAILED;
    error: Error
}


export type TComplaintType =
    | IComplaintRequest
    | IComplaintSuccess
    | IComplaintFailed
    | ISendOtpRequest
    | ISendOtpSuccess
    | ISendOtpFailed
    | IConfirmOtpRequest
    | IConfirmOtpSuccess
    | IConfirmOtpFailed
    | IComplaintAssessmentRequest
    | IComplaintAssessmentSuccess
    | IComplaintAssessmentFailed
    | ISendEmailRequest
    | ISendEmailSuccess
    | ISendEmailFailed
    ;

